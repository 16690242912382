import ProjectDescTemplate from "../components/ProjectDescTemplate";
import { ProjectList } from "../../../Store";
import Conclusion from "../components/Conclusion";
import Challenges from "../components/Challenges";
import Implementation from "../components/Implementation";
import Background from "../components/Background";
import Overview from "../components/Overview";
import { Sections } from "../components/Sections";
import Stack from "../components/Stack";

const Portfolio3D = () => {
  const description = `
  This project is a virtual simulation of my bedroom, built using Three.js (a WebGL-based graphics library) and React. It includes an animated avatar of myself that interacts with the environment and responds to user input. The avatar features context-based animations that adapt to its mode, making it more lifelike.
    `;

  const sections: Sections = {
    Overview: {
      name: "Overview",
      section: (
        <Overview
          projectName="3D Portfolio"
          websiteUrl="https://3dportfolio.up.railway.app/"
          githubUrl="https://github.com/Eykam/3DPortfolio"
          description={description}
        />
      ),
    },
    Stack: {
      name: "Stack",
      section: (
        <Stack
          stack={{
            Frontend: ["React", "Three.js"],
            Graphics: ["WebGL"],
            AI: ["RAG (Retrieval-Augmented Generation)", "Eleven Labs"],
            Backend: ["TBD (Chat Pipeline in Development)"],
            DevOps: ["Git", "Railway"],
          }}
        />
      ),
    },
    Background: {
      name: "Background",
      section: (
        <Background
          description={
            <div style={{ lineHeight: "1.6", fontSize: "16px" }}>
              <p>
                The project was inspired by a desire to combine interactive 3D
                graphics with AI-based interactivity. It explores the potential
                for creating a highly immersive, personal virtual assistant that
                is both functional and engaging. The avatar not only showcases
                my projects but also acts as a conversational interface to
                discuss them.
              </p>
            </div>
          }
        />
      ),
    },
    Implementation: {
      name: "Implementation",
      section: (
        <Implementation
          description={
            <div style={{ lineHeight: "1.6", fontSize: "16px" }}>
              <p>
                The simulation is built using <strong>Three.js</strong> for
                rendering 3D graphics and <strong>React</strong> for the
                frontend. Animations for the avatar are created based on its
                current mode using Three.js and synchronized with audio
                generated by Eleven Labs. The chat pipeline, once implemented,
                will enable the avatar to retrieve and generate responses using
                a <strong>Retrieval-Augmented Generation</strong> model.
              </p>
            </div>
          }
        />
      ),
    },
    Challenges: {
      name: "Challenges",
      section: (
        <Challenges
          description={
            <div style={{ lineHeight: "1.6", fontSize: "16px" }}>
              <p>
                Integrating lifelike animations, gestures, and visemes into the
                avatar posed a challenge in achieving seamless synchronization
                with voice responses. Building an efficient chat pipeline to
                process and retrieve information about my projects required
                careful consideration of both scalability and relevance.
              </p>
            </div>
          }
        />
      ),
    },
    Conclusion: {
      name: "Conclusion",
      section: (
        <Conclusion
          description={
            <div style={{ lineHeight: "1.6", fontSize: "16px" }}>
              <p>
                This project demonstrates the integration of cutting-edge
                technologies such as WebGL, AI, and 3D rendering to create a
                personalized virtual experience. It serves as both a portfolio
                and a proof of concept for combining immersive simulations with
                conversational AI.
              </p>
              <br />
              <br />
              <p>
                Future functionality will include a chat pipeline where the
                avatar can answer questions about me and my projects. This will
                be achieved through{" "}
                <strong>Retrieval-Augmented Generation (RAG)</strong>, trained
                on my personal projects, GitHub repositories, and other data.
                Additionally, the avatar is powered by{" "}
                <strong>Eleven Labs</strong> to replicate my voice and includes
                visemes and gestures synchronized with its responses for an
                immersive experience.
              </p>
            </div>
          }
        />
      ),
    },
  };

  return (
    <>
      <ProjectDescTemplate
        sections={sections}
        source={ProjectList["3D-Portfolio"].thumbnail}
      />
    </>
  );
};

export default Portfolio3D;
